import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { SavedSearchIndex } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';

import { isLocationEmpty } from './sidefilters';

export const makeSavedSearchLocation = (searchLocation: SearchLocation) =>
  isLocationEmpty(searchLocation)
    ? { ...searchLocation, hasGeo: false }
    : { ...searchLocation, hasGeo: true };

export const filterToIndex = (t: SearchType): SavedSearchIndex => {
  switch (t) {
    case 'VOLOP':
      return 'ACTIONS';

    default:
      return 'MAIN';
  }
};
