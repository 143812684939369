import { Box } from 'components/Box/Box';
import { useFormContext } from 'components/Form/FormContext';
import { InputField } from 'components/Form/InputField';
import { SubmitButton } from 'components/Form/SubmitButton/SubmitButton';
import { Label } from 'components/Label/Label';
import { Switch } from 'components/Switch/Switch';
import { spacing } from 'theme/theme';

export function EditSavedSearchForm() {
  const { values, fieldQaIdPrefix, updateValue } = useFormContext();

  return (
    <>
      <Box mb="24px">
        <InputField label={getText('Name your search')} name="name" noMargin />
      </Box>
      <Box mb="24px" display="flex" gridGap={spacing[12]}>
        <Switch
          id={`${fieldQaIdPrefix}-notify-by-email`}
          qaId={`${fieldQaIdPrefix}-notify-by-email`}
          name="notifyByEmail"
          checked={values.notifyByEmail}
          onChange={(checked) => updateValue('notifyByEmail', checked)}
        />
        <Label htmlFor={`${fieldQaIdPrefix}-notify-by-email`}>
          {getText('Email Alerts for this search')}
        </Label>
      </Box>
      <SubmitButton fullWidth>{getText('Update')}</SubmitButton>
    </>
  );
}
