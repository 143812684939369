import { useEffect } from 'react';

import { Button } from 'components/Button/Button';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useCurrentSavedSearch } from 'modules/search/hooks/useCurrentSavedSearch';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { userDashboardSavedItemsRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import {
  useRecentlySavedSearchId,
  userDashboardDismissRecentlySavedSearch,
} from 'modules/userDashboard/zustand-stores/userDashboardSavedSearchesStore';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { useModal } from 'zustand-stores/modalStore';

import { SaveSearchButtonHolder } from './SaveSearchButton.styled';
import { SaveSearchButtonTooltip } from './SaveSearchButtonTooltip';

type Props = {
  saveSearch: () => void;
  searchFacets: SearchFacet[];
};

export function SaveSearchButton({ saveSearch, searchFacets }: Props) {
  const { savedSearch } = useCurrentSavedSearch({ searchFacets });

  const { recentlySavedSearchId } = useRecentlySavedSearchId();

  useEffect(
    () => () => {
      userDashboardDismissRecentlySavedSearch();
    },
    [],
  );

  const { modal } = useModal();

  if (savedSearch) {
    return (
      <SaveSearchButtonHolder data-qa-id="save-search">
        <Tooltip
          content={
            <SaveSearchButtonTooltip
              savedSearch={savedSearch}
              onSavedOrDismissed={() =>
                userDashboardDismissRecentlySavedSearch()
              }
            />
          }
          size="large"
          placement="bottom-left"
          tooltipWidth={270}
          renderCondition={savedSearch.id === recentlySavedSearchId && !modal}
          variant="brand-green"
          withArrow={false}
          closeable
          onClose={() => userDashboardDismissRecentlySavedSearch()}
        >
          <Button
            type="link"
            icon={{ name: 'bookmark', type: 'normalized' }}
            variant="secondary"
            href={userDashboardSavedItemsRoute.with({ tab: 'SEARCH' })}
            data-qa-id="save-search"
          >
            {getText('My Saved Searches')}
          </Button>
        </Tooltip>
      </SaveSearchButtonHolder>
    );
  }

  return (
    <Button
      type="button"
      onClick={async (e) => {
        e.stopPropagation();
        trackClicked('Get Email Alerts');
        saveSearch();
      }}
      icon={{ name: 'bookmark', type: 'normalized' }}
      variant="secondary"
      data-qa-id="save-search"
    >
      {getText('Save Search')}
    </Button>
  );
}
