import { Form } from 'components/Form/Form';
import { UserDashboardApiSavedSearch } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';
import { userDashboardEditSavedSearch } from 'modules/userDashboard/zustand-stores/userDashboardSavedSearchesStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';

import { EditSavedSearchForm } from './EditSavedSearchForm';
import { EditSavedSearchFormValues } from './EditSavedSearchFormValues';

type Props = {
  savedSearch: UserDashboardApiSavedSearch;
  onSavedOrDismissed: () => void;
};

export function SaveSearchButtonTooltip({
  savedSearch: { id, name, notifyByEmail },
  onSavedOrDismissed,
}: Props) {
  const onSubmit = async ({
    values,
  }: {
    values: EditSavedSearchFormValues;
  }) => {
    await userDashboardEditSavedSearch(id, values);
    trackEvent(
      values.notifyByEmail
        ? 'Opted into email alerts'
        : 'Opted out of email alerts',
      {
        context: 'Modal',
      },
    );
    if (values.name !== name) {
      trackEvent('Updated Saved Search Name', {
        context: 'Modal',
      });
    }

    onSavedOrDismissed();
  };

  return (
    <div data-qa-id="edit-saved-search-tooltip">
      <Form
        analyticsTitle="Edit Saved Search"
        initialValues={{
          name,
          notifyByEmail,
        }}
        usageContext="modal"
        data-qa-id="edit-saved-search-form"
        onSubmit={onSubmit}
        render={() => <EditSavedSearchForm />}
      />
    </div>
  );
}
