import deepEqual from 'react-fast-compare';

import { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';

import { getSavedSearchObjectForComparison } from './getSavedSearchObjectForComparison';

export function searchesAreEqual(
  s1: ApiSavedSearchUnsaved,
  s2: ApiSavedSearchUnsaved,
) {
  const s1Keys = getSavedSearchObjectForComparison(s1);
  const s2Keys = getSavedSearchObjectForComparison(s2);
  return deepEqual(s1Keys, s2Keys);
}
