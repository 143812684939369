import { ApiISODateString } from 'api/types/ApiTypedDate';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { filterNulls, objectEmpty } from 'utils/functional/object';

type Args = {
  filtersById: SearchFiltersById;
  searchFacets: SearchFacet[];
};

/**
 * stateSearchFilters takes the redux filter state and
 * outputs an "almost" saved-search-serializable data structure
 */
export function almostSavedSearchSerializableDataStructure({
  filtersById,
  searchFacets,
}: Args): SearchFiltersById {
  let filters = {} as SearchFiltersById;

  if (filtersById) {
    filters.type = filtersById.type;
  }

  searchFacets.forEach((facet) => {
    const setting = filtersById[facet.id];

    if (facet.type === 'array') {
      // Check if the array has all options in it aswell
      if (!setting || (setting as string[]).length === 0) return;
      const allOptionsSelected = facet.options.every(
        (i) => Array.isArray(setting) && setting.includes(i),
      );
      if (!allOptionsSelected) filters[facet.id] = setting;
    } else if (facet.type === 'string') {
      if (!setting || (setting as string).length === 0) return;
      const [optionSelected] = setting as string;
      filters[facet.id] = optionSelected;
    } else if (facet.type === 'date') {
      const s = setting as Record<string, ApiISODateString>;

      if (!objectEmpty(s)) {
        // @ts-expect-error fix types
        filters = filterNulls({ ...filters, [facet.id]: undefined, ...s });
      }
    }
  });

  return filters;
}
