import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getSearchPropertiesFromState } from 'modules/search/helpers/savedSearch/getSearchPropertiesFromState';
import { removeUnsaveableFilters } from 'modules/search/helpers/savedSearch/removeUnsaveableFilters';
import { searchesAreEqual } from 'modules/search/helpers/savedSearch/searchesAreEqual';
import { serializeFiltersForSavedSearch } from 'modules/search/helpers/savedSearch/serializeFiltersForSavedSearch';
import { filterToIndex } from 'modules/search/helpers/savedSearches';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import {
  useSearchFilterType,
  useSearchFiltersById,
  useSearchLocation,
  useSearchQuery,
  useSearchRadius,
} from 'modules/search/zustand-stores/searchStore';
import { UserDashboardApiSavedSearch } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';
import { useUserDashboardSavedSearches } from 'modules/userDashboard/zustand-stores/userDashboardSavedSearchesStore';

type Args = {
  searchFacets: SearchFacet[];
};

export function useCurrentSavedSearch({ searchFacets }: Args): {
  savedSearch: UserDashboardApiSavedSearch | null | undefined;
} {
  const location = useLocation();

  const { savedSearches } = useUserDashboardSavedSearches();

  const query = useSearchQuery();
  const searchFilterType = useSearchFilterType();
  const radius = useSearchRadius();
  const filtersById = useSearchFiltersById();
  const searchLocation = useSearchLocation();

  const saveableSearchFilters = useMemo(() => {
    const searchState = getSearchPropertiesFromState({
      query,
      searchFacets,
      searchLocation,
      radius,
      filtersById,
      searchFilterType,
    });

    return removeUnsaveableFilters({
      ...searchState,
      index: filterToIndex(searchFilterType),
      filters: serializeFiltersForSavedSearch({
        filtersById,
        queryParams: queryString.parse(location.search),
        searchFacets,
      }),
    });
  }, [
    filtersById,
    location.search,
    query,
    radius,
    searchFacets,
    searchFilterType,
    searchLocation,
  ]);

  const savedSearch = useMemo(
    () =>
      savedSearches?.find((s) => searchesAreEqual(s, saveableSearchFilters)),
    [savedSearches, saveableSearchFilters],
  );

  return { savedSearch };
}
